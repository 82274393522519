export const FUNNEL_EVENTS = {
    INIT: { action: 'APF_Init', category: 'Sellers', label: 'AddPostScreen' },
    PREVIEW: { action: 'APF_Preview', category: 'Sellers', label: 'Preview_AddPostScreen' },
    SUBMIT: { action: 'APF_Submit', category: 'Sellers', label: 'SubmitBtn_AddPostScreen' },
    SUCCESS: { action: 'APF_Success', category: 'Sellers', label: 'API_AddPostScreen' },
    DROP: { action: 'APF_Drop', category: 'Sellers', label: 'CloseBtn_AddPostScreen' },
};

const getEventQueue = (): Array<{ action: string; category: string; label: string }> => {
    const queue = sessionStorage.getItem('eventQueue');
    return queue ? JSON.parse(queue) : [];
};

const saveEventQueue = (queue: Array<{ action: string; category: string; label: string }>) => {
    sessionStorage.setItem('eventQueue', JSON.stringify(queue));
};

export const trackEvent = (event: { action: string; category: string; label: string }) => {
    let eventQueue = getEventQueue();
    if (!eventQueue.some((e) => e.action === event.action)) {
        eventQueue.push(event);
        saveEventQueue(eventQueue);
    }
};


export const pushEventsToDataLayer = () => {
    let eventQueue = getEventQueue();
    eventQueue.forEach((event) => {
        window.dataLayer.push({
            event: "UserInteraction",
            eventAction: event.action,
            eventCategory: event.category,
            eventLabel: event.label,
        });
    });
    sessionStorage.removeItem('eventQueue');
};


