import React, {useEffect, useMemo, useState} from "react";
import DynamicObject from "../models/dynamic-object";
import {StateDataType as Media} from "../components/UI/MediaUpload/MediaUpload.interfaces";
import FlowReferences from "../flow-references";


const flowRefs: DynamicObject = {}
const flowComponents: DynamicObject = {}
const flowLevels: DynamicObject = {}

export {
    flowRefs,
    flowLevels,
    flowComponents
}

export interface MediaResult {
    [key: string]: Media
}

interface BaseDataType {
    workflow_id: string,
    draft_id: string,
    remaining_edit_counter?:string|number
    start_time: number,
    step?: DynamicObject,
    previous?: DynamicObject,
    flow_type?: string,
    isFinished?: boolean,
    isEdit?: boolean,
    post_id?: number,
    overLimitType?: string,
    isOverLimit? : boolean
}

export interface PostDataType extends BaseDataType {
    form: DynamicObject,
    currentStepData: DynamicObject,
    media: MediaResult
}


const DEFAULT_DATA: PostDataType = {
    form: {},
    currentStepData: {},
    workflow_id: "",
    draft_id: "",
    remaining_edit_counter:undefined,
    start_time: 0,
    step: undefined,
    flow_type: 'post',
    media: {},
    isFinished: false,
    isEdit:false,
    post_id: 0,
    overLimitType: '',
    isOverLimit: false
}



const PostContext = React.createContext({
        data: DEFAULT_DATA,
        previewStep: {},

        addToForm: (key: string, value: any) => {
        },
        updateForm: (key: string, value: any) => {
        },
        updateData: (data: DynamicObject): void => {
        },
        updatePreviewStep: (data: DynamicObject): void => {
        },
        updateStep: (data: BaseDataType): void => {
        }
    }
)

export const PostContextProvider = (props: { children: React.ReactNode }) => {

    //states
    const [data, setData] = useState<PostDataType>(DEFAULT_DATA);
    const [previewStep, setPreviewStep] = useState<DynamicObject>({});
    useEffect(() => {

    }, [data])

    const addToForm = (key: string, value: any) => {
        console.log(flowRefs, 'flowRefs')
        setData((old: PostDataType) => {
            const newData = {
                ...old
            }
            for (const key in flowRefs) {
                if (flowRefs[key].current?.state?.get){
                    newData.form[key] = flowRefs[key].current?.state?.get()
                }
            }

            FlowReferences.map((identifier, field) => {
                newData.form[key] = field?.state?.get()
            })

            newData.form[key] = value
            newData.currentStepData[key] = true
            return newData
        })
    }

    const updateForm = (key: string, value: any) => {
        setData((old: PostDataType) => {
            return {
                ...old,
                [`${key}`]: value
            }
        })
    }

    const updateData = (newData: DynamicObject) => {
        setData((old: PostDataType) => {
            // for (const key in flowRefs) {
            //     if (flowRefs[key].current?.state?.get){
            //         console.log(key, flowRefs[key].current?.state?.get())
            //         newData.form[key] = flowRefs[key].current?.state?.get()
            //     }
            // }
            return {
                ...old,
                ...newData
            }
        })
    }

    const updateStep = (newData: BaseDataType) => {
        setData((old: PostDataType) => {
            return {
                ...old,
                ...newData,
                form: old.form,
                currentStepData: {},
            }
        })
    }

    const updatePreviewStep = (data: DynamicObject) => {
        setPreviewStep(data)
    }





    return (
        <PostContext.Provider
            value={{
                data,
                previewStep,
                updateData,
                updatePreviewStep,
                updateStep,
                addToForm,
                updateForm
            }}>
            {props.children}
        </PostContext.Provider>
    );
}




export default PostContext