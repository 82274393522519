import {Modal} from "../../../../UI";
import {ChildProps, Props} from "./../interface";
import {useHttp} from "../../../../../hooks";
import styles from './SelectFieldMobile.module.css'
import {IoIosArrowDown} from "react-icons/io";
import {AxiosError, AxiosResponse} from "axios";
import PostContext, {flowRefs} from "../../../../../storage/PostContext";
import {Args} from "../../../../../hooks/use-http/interfaces";
import MapperSelectField from "../../../../MapperSelectField";
import React, {CSSProperties, useCallback, useContext, useEffect, useRef, useState} from "react";
import DynamicObject from "../../../../../models/dynamic-object";
import CircularProgress from '@mui/material/CircularProgress';
import {configWidgetApi} from "../../../../../config/api.config";
import {getItemsAsString, getSizes, removeMatchingKeys} from "../../../../../helpers/functions";
import useDataPayload, {RefDataType} from "../../../../../hooks/use-data-payload/use-data-payload";
import {useTranslation} from "react-i18next";
import FlowReferences from "../../../../../flow-references";
import NotAutoSaveState from "../../../../../not-auto-save-state";
import LanguageContext from "../../../../../storage/LanguageContext";

interface CurrentProps extends ChildProps {
    state
}

const SelectFieldMobile = (props: CurrentProps) => {

    const {config, validation, state} = props

    /*--------------------------------------------------------------
     *                             HOOKS
     * ------------------------------------------------------------*/

    /************************************
     *   SET & GET VALIDATION AND STATES
     * *********************************/

    /*---------------------------------------------------------------*/
    /************************************
     *           CONTEXT
     * *********************************/
    const postCtx = useContext(PostContext)
    const langCtx=useContext(LanguageContext)


    /************************************
     *            STATES
     * *********************************/
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [btnLoader, setBtnLoader] = useState<boolean>(false)
    const [currentConfigApi, setCurrentConfigApi] = useState<DynamicObject>({})
    const [tempFormData, setTempFormData] = useState<DynamicObject>({})
    const [disabled, setDisabled] = useState(!props.config.editable);

    //disabled when empty the parent

    /************************************
     *           CONSTANTS
     * *********************************/
    const widgetApi = useHttp<DynamicObject>(configWidgetApi)

    let selectedItems = state.value
    if (selectedItems && typeof selectedItems !== "string") {
        selectedItems = selectedItems.toString();
    }
    selectedItems = selectedItems ? selectedItems.split(',') : []
    const {t} = useTranslation()

    const request = widgetApi.request

    useEffect(() => {
    }, [postCtx.data.form[config.identifier]]);

    /************************************
     *        PREPARE REQUEST
     * *********************************/
    widgetApi.request = (args?: Args) => {
        const params: DynamicObject = {
            id: config.identifier,
            type: 'add-post',
            workflowId: postCtx.data.workflow_id,
            draftId: postCtx.data.draft_id,
            stepId: postCtx.data.step?.identifier
        }

        if (props.config?.parent?.length) {
            for (const field of props.config.parent) {
                params[field] = FlowReferences.get(field)?.state?.get() || postCtx.data.form[field]
            }
        }

        return request({
            ...args,
            config: {
                ...args?.config,
                language: document.cookie.replace(/(?:(?:^|.*;\s*)lang\s*\=\s*([^;]*).*$)|^.*$/, "$1"),
                params
            }
        })
    }

    /*--------------------------------------------------------------
     *                         FUNCTIONS
     * ------------------------------------------------------------*/


    const isDisabled = useCallback(() => {
        let isParentsFilled = false
        props.config?.parent.map((identifier) => {

            if (FlowReferences.get(identifier)?.state?.get())
                isParentsFilled = true
        })


        setDisabled(!isParentsFilled);
    }, [props.config])


    const onClear = useCallback(() => {
        isDisabled()
    }, [isDisabled])

    useEffect(() => {
        FlowReferences.actions[config.identifier] = {
            ...FlowReferences.actions[config.identifier],
            onClear
        }
    }, [onClear])


    useEffect(() => {
        if (props.config?.parent?.length > 0) {
            isDisabled()

        }

    }, [isDisabled]);
    /************************************
     *         ACTIONS HANDLER
     * *********************************/
    const onSuccessWidgetApi = (response: AxiosResponse) => {
        const data: DynamicObject = {}
        for (const key in props.flowRefs) {
            const ref = props.flowRefs[key]
            if (!ref?.current?.state?.get)
                continue

            const state = ref.current.state.get()
            const keys = key.match(/\b(\w+)\b/g) || []


            if (keys.length > 1) {
                let mainKey = keys[0] || ''
                if (!data[mainKey])
                    data[mainKey] = {}

                for (let i = 1; i < keys.length; i++) {
                    let name = keys[i]
                    data[mainKey][name] = state
                }
            } else {
                if (typeof state === "object") {
                    if (!data[key])
                        data[key] = {}

                    removeMatchingKeys(Object.keys(data[key]), state)
                    data[key] = {...data[key], ...state}
                } else {
                    data[key] = state
                }
            }
        }

        setTempFormData(data)
        setCurrentConfigApi(response.data)
        setIsOpen(true)
        setBtnLoader(false)

    }
    const onErrorWidgetApi = (response: AxiosError) => {
        setBtnLoader(false)
    }

    const onOpenHandler = () => {
        if (!config.editable)
            return

        setBtnLoader(true)
        widgetApi.request({
            callbacks: {
                success: onSuccessWidgetApi,
                error: onErrorWidgetApi
            }
        })
    }
    /*---------------------------------------------------------------*/


    /************************************
     *         INLINE STYLE
     * *********************************/
    const labelStyle = {
        color: config.title.color,
        fontSize: getSizes(config.title.style?.size),
        fontWeight: config.title.style?.bold ? "bold" : "normal",
    } as React.CSSProperties
    const textStyle = {
        color: config.text.color,
        fontSize: getSizes(config.text?.style?.size),
        fontWeight: config.text?.style?.bold ? "bold" : "normal",
        unicodeBidi: langCtx.language=='ar'?"plaintext":"",
        direction: langCtx.language=='ar'?"rtl":'',
        width: langCtx.language=='ar'?"fit-content":'',
    } as React.CSSProperties

    const disabledStyle = {
        pointerEvents: !config.editable || disabled ? 'none' : "auto",
        background: !config.editable || disabled ? '#f9f9f9' : '',
        cursor: !config.editable || disabled ? 'none' : "pointer",
    } as CSSProperties
    /************************************
     *           GET ITEMS
     * *********************************/

    const values: DynamicObject = {}

    for (const value of config.values) {
        values[value.id] = value
    }

    let items = []

    if (selectedItems.length)
        items = selectedItems.map(function (item, i) {
            return values[item]?.label
        })


    function initLocationItems() {
        items = selectedItems

        if (items[0] == "" || items[0] == "NaN" || items.length <= 0) {
            items = []
            return
        }

        items = [`${t('selectedLocation')}`]
    }

    if (config.identifier.toLowerCase().includes("poslocation") && (config.parent.length > 0 && FlowReferences?.get(config?.parent[0])?.state.get())){
        initLocationItems();
    }

    const onChangeValues = (values, valuesConfig = [], callback = () => {
    }) => {
        state.set(values, callback)
        setIsOpen(false)
        validation.clear()
        props.setConfig({
            ...config,
            values: [...valuesConfig]
        })
        FlowReferences.reRenderedFlow[config.identifier] = true
    }


    /************************************
     * PREVENT REDUNDANT IN RETURNED ITEMS
     * *********************************/
    items = items.filter((value, index, self) => value !== null && value !== undefined && self.indexOf(value) === index);


    return (
        <>
            <div id={config.identifier} className={`${styles.container} `}>
                {isOpen && currentConfigApi &&
                    <Modal title={currentConfigApi.title} setIsOpen={setIsOpen}>
                        <MapperSelectField
                            selectFieldLevels={props.levels}
                            setConfig={props.setConfig}
                            onChangeValues={onChangeValues}
                            tempFormData={tempFormData}
                            selectFieldConfig={config}
                            value={state.value}
                            config={currentConfigApi} setConfigData={props.setConfigData}/>
                    </Modal>}

                {/* ITEMS */}
                <div className={`${styles.items_container} `} style={{
                    ...disabledStyle,
                    borderRadius: "8px",
                }}>
                    <div key={1} className={`row ${styles.item}`}
                         style={disabledStyle}
                         onClick={(e: React.MouseEvent<HTMLElement>) => onOpenHandler()}>
                        <div className={`col-10 ${styles.image_text}`}>

                            {/*ITEM LABEL*/}
                            <div className={styles.text}>
                                <h1 style={labelStyle}>{config.title.locale}</h1>
                                {items.length > 0 &&
                                    <p className={`${styles.text_overflow}`}
                                       style={textStyle}>{getItemsAsString(items)}</p>
                                }
                            </div>
                        </div>
                        {/*ITEM ARROW */}
                        <div className={`col-2 ${styles.icon}`}>
                            {btnLoader ? <CircularProgress/> :
                                <IoIosArrowDown style={{height: "21.2px", width: "22.85px"}}/>}
                        </div>
                    </div>
                </div>

            </div>
        </>


    )
}

export default SelectFieldMobile