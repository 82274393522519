import ArachnaApi from './requests/arachna-api'
import {AxiosResponse} from "axios";
import DynamicObject from "../models/dynamic-object";
import {AddPostFilterResponse} from "../interceptors";
import LoginRequestInterceptor from "../interceptors/platform-request.interceptor";

export default (config?: DynamicObject): Promise<AxiosResponse> => {
    const request = ArachnaApi.getInstance().request
    request.interceptors.response.use(AddPostFilterResponse().success, AddPostFilterResponse().error)
//dynamic-forms.local/vertical/forms/v1/add-post/deeplink/Autos/CarsForSale/pictures/video/no-tictik/details
   //handle survey flow based on the returned uri
    if (config?.uri && config?.uri?.includes('survey')) {
        var url = `${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/survey/deeplink${config.uri}`
    } else {
        var url = `${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/add-post/deeplink${config.uri}`

    }
    return request.get(url, {
        params: {
            draftId: config.draftId
        }
    })
}