import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Props} from "./../interface";
import {FaTimesCircle} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import styles from './ListCheckboxOptionsDesktop.module.css'
import PostContext from "../../../../../storage/PostContext";
import DynamicObject from "../../../../../models/dynamic-object";
import useSearch from "../../../../../hooks/use-search/use-search";
import {arrayIsEmpty, getItemsAsString, getSizes} from "../../../../../helpers/functions";
import ValidationError from "../../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../../hooks/use-data-payload/use-data-payload";
import {Button as Muibutton, Checkbox as CheckboxMui, FormControlLabel, FormGroup, Typography} from "@mui/material";
import FlowReferences from "../../../../../flow-references";
import NotAutoSaveState from "../../../../../not-auto-save-state";

interface CurrentProps extends Props {
    state: DynamicObject,
    validation: DynamicObject,
    onChangeValues: (values, configValues?) => void
}


const ListCheckboxOptionsDesktop = React.forwardRef<RefDataType<string>, CurrentProps>((props, ref) => {
    const {config, state, validation} = props

    /*-------------------------------------
     *              HOOKS
     * ----------------------------------*/


    /************************************
     *       CUSTOME SEARCH HOOK
     * *********************************/
    const {filteredResults, Search} = useSearch({
        items: config.values,
        search: {
            placeholder: config?.search?.placeholder?.locale
        }
    })

    /*---------------------------------------------------------------*/

    /************************************
     *          TRANSLATION
     * *********************************/
    const {t} = useTranslation();

    /************************************
     *            CONTEXT
     * *********************************/
    const postCtx = useContext(PostContext)

    /*-------------------------------------
     *            FUNCTIONS
     * ----------------------------------*/

    /************************************
     *         GET ITEM INFO
     * *********************************/
    const getItemInfo = (id: number | string) => {
        return config?.values?.find(item => item.id == id)
    }

    /************************************
     *        PREPARE DATE
     * *********************************/
    const prepareData = () => {
        const items: DynamicObject = {}
        const dataItems = state.value ? state.value?.split(',') ?? [] : []
        if (dataItems.length > 0) {
            dataItems.map((value: any) => {
                const item: any = getItemInfo(value)
                items[`${item.id}` as string] = item.label
            })
        }
        return items
    }

    /*-------------------------------------
    *            STATE
    * ----------------------------------*/
    const [checkedValues, setCheckedValues] = useState<DynamicObject>(prepareData)

    const valuesRef = useRef(null)


    /************************************
     *        CHECK LINKED
     * *********************************/
    const resetLinked = () => {
        if (!config.linked?.length) {
            return
        }
        config.linked.map(identifier => {
            NotAutoSaveState.set(identifier)
            FlowReferences.get(identifier).state.clear()
        })
    }

    /*---------------------------------------------------------------*/

    /************************************
     *       ACTIONS HANDLER
     * *********************************/
    const addItemHandler = (item: DynamicObject) => {
        setCheckedValues(old => {
            return {
                ...old,
                [`${item.id}`]: item.label
            }
        })
        validation.clear()
    }
    /************************************
     *     UNCHECK ITEMS HANDLER
     * *********************************/
    const removeItemHandler = (item: DynamicObject) => {
        setCheckedValues(old => {
            const state = {...old}

            delete state[item.id]

            return state
        })
    }

    /************************************
     *     ON CHANGE HANDLER
     * *********************************/
    const changeItem = (e: React.ChangeEvent<HTMLInputElement>, item: DynamicObject) => {
        if (!e.target.checked) {
            removeItemHandler(item)
            return
        }
        addItemHandler(item)
        resetLinked()
    }

    /***********************************
     *    GET CHECKED ITEMS & UPDATE
     *         ON CONTEXT
     * *********************************/
    const pushItems = (values) => {
        const stringValues = getItemsAsString(Object.keys({...values})).trim()
        if (stringValues === state.value)
            return



        var result = Object.keys(values).map(function (key) {
            return {id: Number(key), label: values[key]};
        });

        let previewStep = postCtx.previewStep
        let child = previewStep as DynamicObject
        for (const level of props.selectFieldLevels) {
            child = child.childs[level]
        }
        let currentValues = [];//in case re-render without return the child in preview

        if (child?.values) {
            child.values = [...result]
        } else {
            currentValues = [...result]
        }
        console.log(currentValues,child?.values,"here to check")

        // props.setConfigData(previewStep);
        if (props.onChangeValues) {
            return props.onChangeValues(stringValues, child?.values ? child?.values : currentValues)
        }
        state.set(stringValues)

    }

    useEffect(() => {

        return () => {
            console.log('call')
            // if (Object.values(valuesRef.current).length)
                pushItems(valuesRef.current || [])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!valuesRef)
            return


        valuesRef.current = checkedValues
        return () => {
        };
    }, [ref, checkedValues]);

    /*---------------------------------------------------------------*/

    return (
        <div style={{padding: "5px"}}>
            {config?.search &&
                <Search></Search>
            }
            {props.config.tags && <div className={`${styles.checked_container}`}>
                {
                    config.values.map((item) => {
                        if (!checkedValues[item.id])
                            return

                        return (<div className={`${styles.checked_item_box}`}>
                            <div className={styles.checked_item_box_container}>
                                <span>{item.label}</span>
                                <FaTimesCircle className={`cursor__pointer`}
                                               onClick={(e: React.MouseEvent) => removeItemHandler(item)}/>
                            </div>
                        </div>)
                    })
                }
            </div>
            }
            <div className={`mt-1 ${styles.scrollDiv} `}>
                <div className={`${styles.container}`}>
                    {
                        filteredResults.length < 1 && (
                            <div className={`row ${styles.no_found_data}`}>
                                <h3>
                                    {t('noResults')}
                                </h3>
                            </div>
                        )
                    }
                    {
                        filteredResults.map((item, i) => {
                            return <>
                                <FormGroup
                                    style={{padding: 0}}
                                >
                                    <FormControlLabel
                                        className={`${styles.dropDownMenuItems} ${i == (filteredResults.length - 1) && styles.border_bottom_none}`}
                                        sx={{padding: "0", margin: 0}}
                                        control={
                                            <CheckboxMui
                                                sx={{
                                                    color: "#BABABA",
                                                    '&.Mui-checked': {
                                                        borderRadius: "55px",
                                                        color: "#0179FF"
                                                    },
                                                }}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeItem(e, item)}
                                                checked={!!checkedValues[item.id]}
                                            />
                                        }
                                        label={
                                            <Typography sx={{
                                                padding: "0px 10px 0 10px",
                                                fontStyle: "normal",
                                                lineHeight: "22px",
                                                fontWeight: "400px",
                                                fontSize: "14px",
                                                color: config.cell.label.color
                                            }}>
                                                {item.label}
                                            </Typography>
                                        }
                                        value={item.id}
                                    />
                                </FormGroup>
                                {/*<div className={`${styles.separator}`}>*/}
                                {/*</div>*/}
                            </>
                        })
                    }

                    {/*<div className={`${styles.button_container}`}>*/}
                    {/*    <Muibutton style={{*/}
                    {/*        height: "40px",*/}
                    {/*        width: "100%",*/}
                    {/*        backgroundColor: "#1976d2",*/}
                    {/*        fontWeight: "bold",*/}
                    {/*        borderRadius: "8px"*/}
                    {/*    }} variant='contained'*/}
                    {/*               className={`d-block`}*/}
                    {/*               onClick={(e) => pushItems()}*/}
                    {/*    >Save</Muibutton>*/}
                    {/*</div>*/}
                </div>

            </div>

            <ValidationError validation={validation}/>
        </div>
    )
})
export default ListCheckboxOptionsDesktop