import {FaAngleLeft, FaAngleRight, FaPhone, FaPhoneAlt, FaTimes} from "react-icons/fa";
import {IoCloseSharp} from "react-icons/io5";

import styles from './Navbar.module.css'
import {OpenSooqLogo} from '../../../assets/images'
import {ShobiddakLogo} from '../../../assets/images'
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import PostContext from "../../../storage/PostContext";
import UIContext from "../../../storage/UIContext";
import {useNavigate} from "react-router-dom";
import {useHttp} from "../../../hooks";
import DynamicObject from "../../../models/dynamic-object";
import previousApi from "../../../api/previous.api";
import LanguageContext from "../../../storage/LanguageContext";
import {Args} from "../../../hooks/use-http/interfaces";
import {AxiosResponse} from "axios";
import {MediaMapper} from "../../../helpers/media.functions";
import {getFinalPayload, getSizes, getUrlBasedOnCountry, isIFrame} from "../../../helpers/functions";
import Previous from "../../UI/Previous/Previous";
import ConfirmationModel from "../../UI/ConfirmationModal/ConfirmationModel";
import {getDataStorage} from "../../../helpers/storage.helper";
import Cookies from "js-cookie";
import {logScreenLoad} from "../../../logging/helpers/commonLoggingFunctions";
import {CATEGORY_PAGE, FAILED_PAGE, PENDING_PAGE, PENDING_PAYMENT_PAGE, SUCCESS_PAGE ,SURVEY} from "../../../constants/Status";
import MediasCacher from "../../../models/medias-cacher";
import survey from "../../Flow/Survey/Survey";
import useVasStore from "../../../storage/VasContext";
import { defaultOptions } from "../../../logging/helpers/commonCookiesFunctions";
import {pushEventsToDataLayer} from "../../../helpers/Gtm";

const Navbar = () => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [showDialogMessage, setShowDialogMessage] = useState<boolean>(true);

    const urlParams = new URLSearchParams(window.location.search)
    const {isPromoteFlow, setTrigger, enableBackButton, promoteFlowCloseText, promoteFlowTitle} =  useVasStore();


    const {t} = useTranslation();
    const postCtx = useContext(PostContext)
    const uiCtx = useContext(UIContext)
    const navigate = useNavigate()

    const previousHttp = useHttp<DynamicObject>({
        callback: previousApi,
        initialData: {}
    })
    const langCtx = useContext(LanguageContext)

    const currentStepData: DynamicObject = {}

    const request = previousHttp.request

    const storage = getDataStorage();

    previousHttp.request = function (args?: Args) {

        uiCtx.setLoading(true)
        const data: DynamicObject = {
            draftId: postCtx.data.draft_id,
            workflowId: postCtx.data.previous?.workflow_id,
            currentStep: postCtx?.data?.previous?.step_identifier,
            flow_type: postCtx.data.flow_type == 'cv' ? 'cv' : postCtx.data.flow_type == 'survey' ? 'survey' : 'add-post'
        }


        const oldSuccess = args?.callbacks?.success

        const onSuccess = (response: AxiosResponse) => {
            postCtx.updateData({
                workflow_id: response.data.workflow_id,
                draft_id: response.data.draft.id,
                step: response.data.step,
                start_time: Date.now(),
                previous: response.data.previous,
                media: MediaMapper(response.data.media),
            })

            if (response.data.draft.id !== postCtx.data.draft_id){
                MediasCacher.getInstance().clear()
            }

            const mediaResult = MediaMapper(response.data.media)

            if (response.data.step?.identifier.includes('CV')) {

                postCtx.updateData({
                    form: {
                        ...data,
                        ...response.data.draft.payload
                    },
                    media: mediaResult
                })
            } else {
                const finalPayload = getFinalPayload(response.data.draft.payload)
                postCtx.updateData({
                    form: {
                        ...data,
                        ...finalPayload
                    },
                    media: mediaResult
                })
            }

            uiCtx.setLoading(false)
        }

        request({
            ...args,
            config: {
                ...args?.config,
                data: {
                    ...args?.config?.data,
                    ...data
                },
                lang: "en"
            },
            callbacks: {
                ...args?.callbacks,
                success: onSuccess
            },

        })
    }

    function callPreviousApi() {
        //logging
        logScreenLoad();
        //
        previousHttp.request(
            {
                config: {
                    data: {
                        draftId: postCtx.data.draft_id,
                        workflowId: postCtx.data.previous?.workflow_id,
                        currentStep: postCtx?.data?.previous?.step_identifier,
                        flow_type: postCtx.data.flow_type == 'cv' && sessionStorage.getItem('flow-type') != 'job' ? 'post' : postCtx.data.flow_type,
                    },
                },
            }
        )
    }

    const onPreviousHandler = () => {
        callPreviousApi();
    }


    const backEventHandler = () => {

        if (postCtx.data.previous) {
            callPreviousApi();
        }
    }


    const addBackEventListener = () => {

        if (isIFrame()) {
            const listener = (e) => {
                if (e.data?.action === "on-previous") {
                    backEventHandler()
                }
            }
            window.addEventListener('message', listener, false)
            return () => {
                window.removeEventListener('message', listener, false)
            }
        }

        window.addEventListener("on-previous", backEventHandler)
        return () => {
            window.removeEventListener("on-previous", backEventHandler)
        }
    }


    // useEffect(addBackEventListener, [postCtx.data]);


    window.onpopstate = (e) => {
        e.preventDefault()
        // window.dispatchEvent(new CustomEvent("on-previous"))
    }


    useEffect(() => {
        if (width <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [width]);


    useEffect(() => {
        window.addEventListener('message', function (event) {
            if (event.data && typeof event.data == "string") {
                if (event.data == 'without_confirm_dialog') {
                    setShowDialogMessage(false);
                }
            }
        });
    }, [])

    if (window.location !== window.parent.location) {
        return <></>
    }


    const onCloseHandler = () => {
        const pathName: string = window.location.pathname.toLowerCase()
        const pathNameAsUri = pathName.split("/");
        const uriCheckDisplayConfirmationArray = [SUCCESS_PAGE, FAILED_PAGE, PENDING_PAYMENT_PAGE, PENDING_PAGE, CATEGORY_PAGE,SURVEY]
        if ((uriCheckDisplayConfirmationArray.some((str) => pathNameAsUri.includes(str)) || !showDialogMessage) || (isPromoteFlow && !promoteFlowCloseText)) {
            onConfirmCloseHandler()

        } else setIsOpen(true)
    }

    const onConfirmCloseHandler = () => {
        const storage = getDataStorage()
        let URL = Cookies.get('addPostRef')
        const domain = process.env.REACT_APP_JO_SOOQ_URL.replace('https://XX', '');
        document.cookie = `addPostRef=;expires=;max-age=0;domain=${domain};path=/`;
        if (!URL || (URL && URL == '')) {
            URL = getUrlBasedOnCountry(storage.country)
        } else if (URL && getOriginDomain() === process.env.REACT_APP_SHOBIDAK_DOMAIN) {
            URL = getUrlBasedOnCountry(storage.country)
        }
        if(postCtx.data.flow_type === 'add' || postCtx.data.flow_type === 'post'){
            pushEventsToDataLayer();
        }
        setTimeout(()=>{
            window.location.replace(URL);
        }, 200)
    }

    const getOriginDomain = () => {
        var hostname = window.location.hostname; // Get the hostname from the current URL
        var parts = hostname.split('.'); // Split the hostname by periods
        if (parts.length > 2) { // Check if there is a subdomain
            hostname = parts.slice(1).join('.'); // Remove the subdomain
        }
        return hostname;
    }


    const getSiteLogo = () => {
        let logo = OpenSooqLogo;
        if (getOriginDomain() === process.env.REACT_APP_SHOBIDAK_DOMAIN) {
            logo = ShobiddakLogo;
        }
        return logo
    }

    return (<>
            <header className={styles.container}>
                <div className={`${styles.items}`}>
                    {((!postCtx?.data?.previous && !isPromoteFlow) || (isPromoteFlow && !enableBackButton)) && !isMobile && <div></div>}
                    {((!postCtx?.data?.previous && !isPromoteFlow) || (isPromoteFlow && !enableBackButton)) && isMobile && <div className={styles.item}>
                        <img className={styles.sideMargin} src={getSiteLogo()} alt="Opensooq"/>
                    </div>}
                    {postCtx?.data?.previous && !isPromoteFlow &&
                        <Previous onClickPreviousHandler={onPreviousHandler} withTitle={storage.isMobile}/>
                    }
                    
                    {isPromoteFlow && enableBackButton &&
                        <Previous onClickPreviousHandler={()=>setTrigger(true)} withTitle={storage.isMobile}/>
                    }


                    {!isMobile && <div style={{position: "absolute", top: "15px", left: "45%"}} className={styles.item}>
                        <img src={getSiteLogo()} alt="Opensooq"/>
                    </div>
                    }
                    <div className={styles.phoneCloseIcon_container}>
                        {isMobile && <>
                            <div className={`${styles.item} ${styles.phone}`}>
                                <a href={`https://opensooq.com/site/dl?requestUri=${storage.country}/contactUs`}>
                                    <FaPhoneAlt style={{
                                        color: "#0179FF"
                                        , marginTop: "2px"
                                    }} className={styles.icon}/></a>
                            </div>
                        </>}
                        <div><IoCloseSharp style={{height: "25px", width: "25px", marginTop: "3px", cursor: "pointer"}}
                                           onClick={onCloseHandler} className={styles.icon}/>
                        </div>


                    </div>

                </div>
            </header>

            {
                isOpen && showDialogMessage &&
                <ConfirmationModel acceptAction={onConfirmCloseHandler} title={(isPromoteFlow && promoteFlowTitle) ? promoteFlowTitle : t('confirmationTitle')}
                                   message={ (isPromoteFlow && promoteFlowCloseText) ? promoteFlowCloseText : t('confirmationMessage')} setIsOpen={setIsOpen}/>
            }
        </>
    )
}

export default Navbar