import React, {useContext, useEffect, useReducer, useState} from "react";
import {
    DraftDataDataType,
    FileDataType,
    MediaAction,
    MediaActionKind,
    MediaList,
    StateDataType
} from "./MediaUpload.interfaces";
import MediaUpload from "./MediaUpload";
import DynamicObject from "../../../models/dynamic-object";
import uploadMediaApiDraft from "../../../api/upload-media-draft.api";
import uploadMediaApi from "../../../api/upload-media.api";
import {generateFileHash} from "../../../helpers/media.functions";
import deleteMediaApi from "../../../api/delete-media.api";
import PostContext from "../../../storage/PostContext";
import useValidationNew from "../../../hooks/use-validation/use-validation-new";
import {t} from "i18next";
import MediasCacher from "../../../models/medias-cacher";
import {useNavigate} from "react-router-dom";
import {parentCallBack} from "../../../helpers/functions";

import reorderMediaApi from "../../../api/reorder-media.api";
import {DefaultSize, getMediaSize, MediaSizes, updateDefaultSize} from "./MediaSettings";
import FlowReferences from "../../../flow-references";

interface MediaProps {
    identifier: string,
    maxLength?: number,
    config: DynamicObject,
    onChangeMedia?: (state: StateDataType) => void,
    error?: string,
    setError?: (error) => void
}


const DEFAULT_MAX_MEDIA_NUMBER = 30
const DEFAULT_STATE = {
    medias: {},
    main_media: ""
}

const defaultOrder = (identifier) => {
    const {main_media} = MediasCacher.getInstance().get(identifier) || DEFAULT_STATE
    let hashes = MediasCacher.getInstance().medias_order[identifier] || []

    if (main_media) {
        hashes = hashes.filter(hash => hash !== main_media)
        hashes.unshift(main_media)
    }
    return hashes
}


const Media = (props: MediaProps): JSX.Element => {
    const {maxLength = DEFAULT_MAX_MEDIA_NUMBER, identifier} = props

    const reducer = (oldState: StateDataType, action: MediaAction): StateDataType => {
        const {type, payload} = action


        switch (type) {
            case MediaActionKind.ADD_MEDIA:
                const data: MediaList = {}
                for (const media of payload.medias) {
                    data[media.hash] = {
                        url: media.url,
                        uri: media.url,
                        progress: 0,
                        isLoaded: false
                    }
                }


                return {
                    ...oldState,
                    medias: {
                        ...oldState.medias,
                        ...data,
                    },
                    main_media: !!payload?.isMain ? payload.medias[0].hash : oldState.main_media
                }
            case MediaActionKind.UPDATE_MEDIA:

                return {
                    ...oldState,
                    medias: {
                        ...oldState.medias,
                        [`${payload.fileHash}`]: {
                            ...oldState.medias[`${payload.fileHash}`],
                            ...payload.data
                        }
                    }
                }
            case MediaActionKind.UPDATE_MEDIAS:
                const updatedMedias: MediaList = {}
                for (const hash in payload.medias) {
                    updatedMedias[hash] = {
                        ...oldState.medias[hash],
                        ...payload.medias[hash]
                    }
                }
                return {
                    ...oldState,
                    medias: {
                        ...oldState.medias,
                        ...updatedMedias
                    }
                }
            case MediaActionKind.CHANGE_MEDIA:

                delete oldState.medias[payload.oldFileHash]
                if (oldState.main_media === payload.oldFileHash)
                    oldState.main_media = payload.new.fileHash

                return {
                    ...oldState,
                    medias: {
                        ...oldState.medias,
                        [`${payload.new.fileHash}`]: {
                            url: payload.new.fileUrl,
                            isMain: !!payload?.new.isMain,
                            progress: 0,
                            isLoaded: false,
                        },
                    }
                }
            case MediaActionKind.DELETE_MEDIA:
                const newState = {...oldState}

                delete newState.medias[payload.fileHash]

                if (newState.main_media === payload.fileHash)
                    newState.main_media = undefined

                const medias = Object.keys(newState.medias)

                if (!newState.main_media && medias.length > 0)
                    newState.main_media = medias[medias.length - 1]

                if (props.onChangeMedia)
                    props.onChangeMedia(newState)

                return {
                    ...newState,
                }

            case MediaActionKind.UPDATE_PROGRESS:
                return {
                    ...oldState,
                    medias: {
                        ...oldState.medias,
                        [`${payload.fileHash}`]: {
                            ...oldState.medias[`${payload.fileHash}`],
                            progress: payload.progress
                        }
                    },
                }
            case MediaActionKind.UPDATE_IS_LOADED:
                return {
                    ...oldState,
                    medias: {
                        ...oldState.medias,
                        [`${payload.fileHash}`]: {
                            ...oldState.medias[`${payload.fileHash}`],
                            isLoaded: payload.isLoaded,
                        }
                    },
                }
            case MediaActionKind.CHANGE_MAIN_MEDIA:
                const allMedias = {...oldState.medias}
                delete allMedias[oldState.main_media as string]

                const result = {
                    ...oldState,
                    medias: {
                        ...allMedias,
                        [`${oldState.main_media}`]: {...oldState.medias[oldState.main_media as string]},
                    },
                    main_media: payload.main_media
                }

                if (props.onChangeMedia)
                    props.onChangeMedia(result)


                return result
            case MediaActionKind.DRAFTING:
                let newData: StateDataType = {
                    ...oldState,
                }

                if (payload.medias) {
                    for (const hash in payload.medias) {
                        newData.medias[hash] = {
                            ...oldState.medias[hash],
                            ...payload.medias[hash],
                            isLoaded: true,
                            progress: 100
                        }
                    }
                    delete payload.medias
                }

                newData = {
                    ...newData,
                    ...payload
                }


                if (props.onChangeMedia)
                    props.onChangeMedia(newData)

                return {
                    ...newData,
                }
            case MediaActionKind.FORCE_CHANGE:
                return {
                    ...payload
                }
            default:
                return {
                    ...oldState
                }
        }
    }

    const postCtx = useContext(PostContext)
    const [state, dispatch] = useReducer(reducer, MediasCacher.getInstance().get(identifier) || DEFAULT_STATE)
    const {setValidationError, clearValidationError, error} = useValidationNew()
    const [mediaSize, setMediaSize] = useState(DefaultSize)
    const [mediasOrder, setMediasOrder] = useState<string[]>(defaultOrder(identifier))


    useEffect(() => {
        if (!props.error)
            return


        setValidationError(props.error)
    }, [props.error])


    const checkIsValid = (file: File) => {

        if (!file.type.match("image.*")) {
            setValidationError(t("validation__file-isn't-not-image"))
            return false
        }
        clearValidationError()
        return true

    }

    const getHashById = (id: string): string => {
        for (const hash in state.medias) {
            const media = state.medias[hash]
            if (media.id == id) {
                return hash
            }
        }
        return ''
    }

    function saveToDraft(draftData: DraftDataDataType) {
        const {uris, mainUri} = draftData

        const data: DynamicObject = {
            [`${identifier}`]: {
                uri: uris,
            },
            identifier: identifier,
            draftId: postCtx.data.draft_id,
            workflowId: postCtx.data.workflow_id,
        }

        if (mainUri)
            data[identifier]["main_media_uri"] = mainUri

        return new Promise<any>((resolve, reject) => {
            setTimeout(() => {
                console.log('settimeout')
                uploadMediaApiDraft({
                    draftId: postCtx.data.draft_id,
                    data,
                    bucket: 8
                }).then((response) => {
                    resolve(response)
                })
            }, 1000)
        })

    }

    const uploadFileRequest = (mediaFiles: FileDataType[], mainHash: string = "") => {
        const promises: Promise<any>[] = []

        for (const mediaFile of mediaFiles) {


            const promise = () => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsArrayBuffer(mediaFile.file)


                reader.onload = (e) =>
                    uploadMediaApi({
                        onUploadProgress: (progress: number) => {
                            dispatch({
                                type: MediaActionKind.UPDATE_PROGRESS,
                                payload: {
                                    fileHash: mediaFile.hash,
                                    progress
                                }
                            })
                        },
                        onDownloadProgress: (progress: number) => {
                            dispatch({
                                type: MediaActionKind.UPDATE_PROGRESS,
                                payload: {
                                    fileHash: mediaFile.hash,
                                    progress
                                }
                            })


                        },
                        data: e?.target?.result,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        media: {
                            hash: mediaFile.hash,
                            identifier,
                        }
                    })
                        .then(response => {


                            // MediasCacher.getInstance().update(identifier, {
                            //     ...MediasCacher.getInstance().get(identifier),
                            //     medias: {
                            //         ...MediasCacher.getInstance().get(identifier)?.medias,
                            //         [`${mediaFile.hash}`]: {
                            //             ...MediasCacher.getInstance().get(identifier)?.medias[mediaFile.hash],
                            //             isLoaded: true,
                            //             progress: 100
                            //         }
                            //     }
                            // })


                            const data: DraftDataDataType = {
                                uris: [response.data.uri],
                            }

                            if (mainHash === mediaFile.hash)
                                data.mainUri = response.data.uri

                            return saveToDraft(data)


                        })
                        .then((responseDraft) => {
                            const medias: DynamicObject = {}
                            const mediaCaches: DynamicObject = {}

                            for (const uri in responseDraft.data.result.data[identifier]) {
                                const postMedia = responseDraft.data.result.data[identifier][uri]
                                const hash = mediaFile.hash
                                medias[hash] = {
                                    id: postMedia.id,
                                    uri: uri,
                                    isLoaded: true,
                                    progress: 100
                                }

                                mediaCaches[hash] = {
                                    ...MediasCacher.getInstance().get(identifier)?.medias[hash],
                                    ...medias[hash]
                                }
                            }

                            dispatch({
                                type: MediaActionKind.DRAFTING,
                                payload: {
                                    medias
                                }
                            })

                            MediasCacher.getInstance().update(identifier, {
                                ...MediasCacher.getInstance().get(identifier),
                                medias: {
                                    ...MediasCacher.getInstance().get(identifier)?.medias,
                                    ...mediaCaches
                                }
                            })


                        }).catch((err) => console.log("error", err))

            })
            promises.push(promise as any)
        }


        return promises;

    }

    const addMedia = (files: FileList | null, mainImage: boolean): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            if (!files?.length)
                return

            const mediaNumber = Object.values(state.medias).length + files.length

            if (mediaNumber > maxLength) {
                setValidationError(t("validation__more_than_max_length_images").toString().format(maxLength))
                return;
            }


            const mediaFiles: FileDataType[] = []
            const mediaCaches: DynamicObject = {}

            const newMediasOrder = []

            for (let index = 0; index < files.length; index++) {
                const file = files[index]
                const hash = generateFileHash(file)

                if (state.medias[hash])
                    continue

                if (!checkIsValid(file))
                    return;

                const url = URL.createObjectURL(file)

                mediaCaches[hash] = {
                    url,
                    uri: url,
                    progress: 0,
                    isLoaded: false
                }

                mediaFiles.push({
                    file,
                    hash,
                    url: url
                })
                newMediasOrder.push(hash)
            }

            const mainImageHash = mainImage ? mediaFiles[0].hash : ''


            MediasCacher.getInstance().update(identifier, {
                ...MediasCacher.getInstance().get(identifier),
                medias: {
                    ...mediaCaches,
                    ...MediasCacher.getInstance().get(identifier)?.medias,
                },
                main_media: mainImageHash || MediasCacher.getInstance().get(identifier)?.main_media
            })


            setMediasOrder((currentMediasOrder) => {
                const newOrder = [
                    ...currentMediasOrder,
                    ...newMediasOrder
                ]
                // MediasCacher.getInstance().changeOrder(identifier, newOrder)
                return newOrder
            })


            dispatch({
                type: MediaActionKind.ADD_MEDIA,
                payload: {
                    medias: mediaFiles,
                    isMain: !!mainImageHash
                }
            })


            const promises = uploadFileRequest(mediaFiles, mainImageHash)

            const syncUpload = async (promises) => {
                for (const promise of promises) {
                    (await promise)()
                }
            }

            syncUpload(promises)

            // Promise.all(promises.map(async promise => (await promise)()))
            //     .then(result => {
            //     })
        });

    }

    function uploadMedia(file: File, fileHash: string) {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file)
        reader.onload = (e) => uploadMediaApi({
            onUploadProgress: (progress: number) => {
                dispatch({
                    type: MediaActionKind.UPDATE_PROGRESS,
                    payload: {
                        fileHash,
                        progress
                    }
                })
            },
            onDownloadProgress: (progress: number) => {
                dispatch({
                    type: MediaActionKind.UPDATE_PROGRESS,
                    payload: {
                        fileHash,
                        progress
                    }
                })
            },
            data: e.target?.result,
            headers: {
                "Content-Type": "multipart/form-data",
            }

        }).then(response => {

            saveToDraft({uris: [response.data.uri]})
                .then(responseDraft => {
                    dispatch({
                        type: MediaActionKind.DRAFTING,
                        payload: {
                            medias: {
                                [`${fileHash}`]: {
                                    id: responseDraft.data.result.data[identifier][response.data.uri].id,
                                }
                            }
                        }
                    })

                    MediasCacher.getInstance().update(identifier, {
                        ...MediasCacher.getInstance().get(identifier),
                        medias: {
                            ...MediasCacher.getInstance().get(identifier)?.medias,

                            [`${fileHash}`]: {
                                ...MediasCacher.getInstance().get(identifier)?.medias[fileHash],
                                progress: 100,
                                isLoaded: true,
                                uri: response.data.uri,
                                id: responseDraft.data.result.data[identifier][response.data.uri].id,
                            }
                        }
                    })
                });
        })
    }

    const changeMainImageHandler = (fileHash: string) => {
        return new Promise(resolve => {
            const data: DraftDataDataType = {
                uris: [state.medias[fileHash].uri as string],
                mainUri: state.medias[fileHash].uri
            }
            saveToDraft(data)
                .then(response => {
                    dispatch({
                        type: MediaActionKind.CHANGE_MAIN_MEDIA,
                        payload: {
                            main_media: fileHash
                        }
                    })

                    MediasCacher.getInstance().update(identifier, {
                        ...MediasCacher.getInstance().get(identifier),
                        main_media: fileHash
                    })

                    resolve(true)
                })
        })

    }

    const changeMainImage = (fileHash: string) => {
        changeMainImageHandler(fileHash).then(() => {

            setMediasOrder((currentMediasOrder) => {
                const mediaIndex = currentMediasOrder.findIndex(ele => ele === fileHash)
                const images = currentMediasOrder.filter(ele => ele !== fileHash)
                const newOrder = [
                    currentMediasOrder[mediaIndex],
                    ...images
                ]
                return [
                    ...newOrder
                ]
            })
        })
    }

    const changeMedia = (files: FileList | null, oldImageHash: string) => {

        if (!files?.length)
            return

        const file = files[0]
        const fileUrl = URL.createObjectURL(file)
        const fileHash = generateFileHash(file)

        if (state.medias[fileHash]) {
            return;
        }

        if (!checkIsValid(file))
            return;


        dispatch({
            type: MediaActionKind.CHANGE_MEDIA,
            payload: {
                oldFileHash: oldImageHash,
                new: {
                    fileHash,
                    fileUrl
                }
            }
        })


        setMediasOrder((currentMediasOrder) => {
            const newOrder = [...currentMediasOrder];
            const index = currentMediasOrder.findIndex(element => element === oldImageHash);
            newOrder[index] = fileHash
            // MediasCacher.getInstance().changeOrder(identifier, newOrder)
            return newOrder
        })

        const mediaObj = {
            ...MediasCacher.getInstance().get(identifier)
        }

        delete mediaObj.medias[oldImageHash]

        if (mediaObj.main_media === oldImageHash)
            mediaObj.main_media = fileHash

        mediaObj.medias[fileHash] = {
            url: fileUrl,
            uri: fileUrl,
            progress: 0,
            isLoaded: false
        }

        MediasCacher.getInstance().update(identifier, {
            ...mediaObj
        })


        deleteMedia(oldImageHash).then(() => {
            uploadMedia(file, fileHash);
        })
    }

    const deleteMedia = (mediaHash: string) => {
        const mediaObject = state.medias[mediaHash]
        return deleteMediaApi({
            mediaId: mediaObject.id
        })
    }

    const onDeleteMedia = (mediaId: string) => {
        const mediaHash = getHashById(mediaId)

        if (!mediaHash)
            return

        deleteMedia(mediaHash)
            .then(() => {
                dispatch({
                    type: MediaActionKind.DELETE_MEDIA,
                    payload: {
                        fileHash: mediaHash
                    }
                })

                const mediaObj = {
                    ...MediasCacher.getInstance().get(identifier)
                }


                if (mediaObj.main_media === mediaHash && mediasOrder.length > 1) {
                    mediaObj.main_media = mediasOrder[1]
                    changeMainImageHandler(mediasOrder[1])
                }

                if (mediaObj.main_media === mediaHash && mediasOrder.length <= 1)
                    mediaObj.main_media = undefined

                delete mediaObj.medias[mediaHash]

                MediasCacher.getInstance().update(identifier, {
                    ...mediaObj
                })

            })

        setMediasOrder((currentMediasOrder) => {
            const newOrder =
                [...currentMediasOrder].filter(hash => mediaHash !== hash)

            // MediasCacher.getInstance().changeOrder(identifier, newOrder)

            return newOrder
        })

    }

    const reOrderMediaHandler = (elementIndex, newIndex) => {
        return new Promise((resolve) => {
            let photos = [...mediasOrder]
            const image = photos[elementIndex]
            photos = photos.filter((photo, index) => elementIndex !== index)

            let newOrder = []
            if (newIndex !== 0) {
                newOrder = [
                    ...photos.slice(0, newIndex),
                ]
            }

            newOrder = [
                ...newOrder,
                image,
                ...photos.slice(newIndex),
            ]

            // is main image
            if ([elementIndex, newIndex].includes(0 as any)) {
                const key = newIndex === 0 ? newOrder[newIndex] : newOrder[elementIndex]
                changeMainImageHandler(key).then(() => {
                    resolve(newOrder)
                })
            }
            // MediasCacher.getInstance().changeOrder(identifier, newOrder)
            resolve(newOrder)

        })
    }

    const reOrderMedia = (elementIndex, newIndex) => {
        reOrderMediaHandler(elementIndex, newIndex).then((newOrder: string[]) => setMediasOrder(newOrder))
    }

    const changeMediaSizeHandler = () => {
        const width = document.getElementById("MediaImagesMainContainer").offsetWidth
        let size = getMediaSize(width)
        if (size !== DefaultSize) {
            setMediaSize(size);
            updateDefaultSize(size)
        }
    }

    useEffect(() => {
        const eventHandler = (e: CustomEvent) => {
            const data = e.detail
            dispatch({
                type: MediaActionKind.FORCE_CHANGE,
                payload: data.medias[identifier]
            })
        }

        parentCallBack('with_confirm_dialog')
        window.addEventListener(`update-media-${identifier}`, eventHandler)
        changeMediaSizeHandler()
        MediasCacher.getInstance().initialMedia(identifier)
        return () => {
            window.removeEventListener(`update-media-${identifier}`, eventHandler)
        }
    }, [])

    function isTwoArrayDifferent(array1, array2) {
        if (array1.length !== array2.length)
            return true

        for (const index in array2) {
            if (array1[index] !== mediasOrder[index]) {
                return true
            }
        }

        return false
    }


    useEffect(() => {
        // if (!mediasOrder.length)
        //     return

        const order = {}
        let isReady = true

        mediasOrder.map(function (hash, index) {
            if (!state.medias[hash].isLoaded)
                isReady = false

            order[state.medias[hash].id] = index
        })

// console.log(mediasOrder, 'mediaOrder')
        let isChanged = true

        if (MediasCacher.getInstance().medias_order[identifier]) {
            isChanged = isTwoArrayDifferent(MediasCacher.getInstance().medias_order[identifier], mediasOrder);
        }

        if (isChanged) {
            MediasCacher.getInstance().changeOrder(identifier, mediasOrder)
        }


        if (!isReady || mediasOrder.length <= 0 || !isTwoArrayDifferent(MediasCacher.getInstance().saved_orders[identifier] || [], mediasOrder))
            return;

        reorderMediaApi({
            data: {...order}
        }).then(function (res) {
            MediasCacher.getInstance().saved_orders = {
                ...MediasCacher.getInstance().saved_orders,
                [`${identifier}`]: mediasOrder
            }
        })

    }, [mediasOrder, state.medias])


    return (
        <MediaUpload
            state={state}
            addMedia={addMedia}
            changeMainImage={changeMainImage}
            changeMedia={changeMedia}
            onDeleteMedia={onDeleteMedia}
            maxLength={DEFAULT_MAX_MEDIA_NUMBER}
            error={error}
            mediasOrder={mediasOrder}
            reOrderMedia={reOrderMedia}
            mediaSize={mediaSize}
            identifier={identifier}
            config={props.config}
        />
    )
}


export default Media